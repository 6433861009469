import style from './section.module.css'
import type { ReactNode } from 'react'

export type SectionProps = {
  ariaLabel: string
  children: ReactNode
  description?: string
  header?: string
  className?: string
}

export default function Section({
  ariaLabel,
  children,
  description,
  header,
  className,
}: SectionProps): JSX.Element {
  const classes = [style.section, className ?? ''].join(' ').trim()
  return (
    <section aria-label={ariaLabel} className={classes}>
      {header ? <h2 className={style.h2}>{header}</h2> : null}
      {description ? <p className={style.description}>{description}</p> : null}
      <div className={style.wrapper}>{children}</div>
    </section>
  )
}
