import useWindowResize from '../../../../hooks/use-window-resize'

/**
 * Create a --vh on html element
 */
const useRealVh = (): void => {
  const handleResize = () => {
    const vh = document.documentElement.clientHeight / 100
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  useWindowResize(handleResize, 200)
}

export default useRealVh
