import { ReactElement, useState } from 'react'
import { useRouter } from 'next/router'
import style from './error-page.module.css'
import Section from '../../welcome-page/main/form-context-provider/section'
import { Button } from '@library/_buttons'
import ErrorIcon from '@components/static-pages/error-page/icons/error-icon'
import { VIDZING_EXPLORE_PAGE } from '@root/constants'
import { WhiteLabelInterface } from '@shared/interfaces/creators/white-label.interface'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'
import styles from './styles.module.scss'

interface Props {
  channelTheme?: ChannelThemeInterface
  routePath?: string
  whiteLabel?: WhiteLabelInterface
  errorMessage?: string
}

export default function ErrorPage({
  channelTheme,
  routePath,
  whiteLabel,
  errorMessage,
}: Props): ReactElement {
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const error = 404
  const DEFAULT_ERROR_MESSAGE = `Sorry we can't find that page! Either something went wrong or the page doesn't
        exist anymore.`

  const handleClick = () => {
    setLoading(true)
    router?.push(routePath ?? VIDZING_EXPLORE_PAGE)
  }

  return (
    <Section className={styles.notFound} ariaLabel={`Oops! ${error}`} header={`Oops! ${error}`}>
      <ErrorIcon className={style.errorImg} color={channelTheme?.buttonColour} />
      <p className={style.p}>{errorMessage ?? DEFAULT_ERROR_MESSAGE}</p>
      <Button
        className={style.buttonBack}
        isLoading={loading}
        onClick={handleClick}
        isFullWidth={false}
        channelTheme={channelTheme}
      >
        Back to Home
      </Button>
    </Section>
  )
}
