import { useEffect } from 'react'
import throttle from 'lodash/throttle'

export default function useWindowResize(handleResize: () => void, wait = 100): void {
  const throttledHandleResize = throttle(handleResize, wait)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', throttledHandleResize)
    return () => {
      window.removeEventListener('resize', throttledHandleResize)
    }
  }, [])
}
