import { ReactNode } from 'react'
import { motion } from 'framer-motion'

export interface Props {
  className?: string
  children: ReactNode
  style?: Record<string, unknown>
}
export default function FadeInWrapper({ className = '', children, style }: Props) {
  const animation = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={animation}
      className={className}
      style={style}
    >
      {children}
    </motion.div>
  )
}
