import Head from 'next/head'
import { hasProp } from '@utils/has-prop'
import { SMALL_GRAPHIC_LOGO } from '@root/constants'
import Header from '@library/header'
import style from '@library/_styles/absolute.module.css'
import ErrorPage from '@components/static-pages/error-page'
import RenderIf from '@utils/render-if'
import PageFrame from '@library/page-frame'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import usePageThemeToUse from '@hooks/_theme/use-page-theme-to-use'
import { FooterVariantEnum } from '@shared/enums/layout/footer.enum'
import Footer from '@components/layout/footer'
import { ReactElement } from 'react'
import use404Theme from '@hooks/_white-label/use-404-theme'
import { AnimatePresence } from 'framer-motion'
import FadeInWrapper from '@ui/motion/fade-in-wrapper'

interface Props {
  channel?: ChannelInterface
  errorMessage?: string
}
export default function Error404({ channel, errorMessage }: Props): ReactElement {
  // Set theme to use
  const pageFrameStyleToUse = usePageThemeToUse()

  const { loading, channelData } = use404Theme(channel)

  // White label the error page if the channel is found and has white label data
  const isWhiteLabel = !!channelData?.organisation?.whiteLabel

  return (
    <>
      <AnimatePresence>
        {!loading && (
          <FadeInWrapper>
            <PageFrame variant={isWhiteLabel ? 'whiteLabel' : pageFrameStyleToUse}>
              <Head>
                {isWhiteLabel && hasProp(channelData?.organisation?.whiteLabel?.faviconUrl) ? (
                  <link rel="icon" href={channelData?.organisation?.whiteLabel?.faviconUrl} />
                ) : (
                  <link rel="icon" href={SMALL_GRAPHIC_LOGO} />
                )}
              </Head>
              <Header
                className={style.absolute}
                hasNav={false}
                whiteLabel={channelData?.organisation?.whiteLabel}
              />
              <main>
                <ErrorPage
                  whiteLabel={channelData?.organisation?.whiteLabel}
                  routePath={channelData?.channelUrl ? `/${channelData?.channelUrl}` : undefined}
                  channelTheme={channelData?.channelTheme}
                  errorMessage={errorMessage}
                />
              </main>
              <RenderIf isTrue={!isWhiteLabel}>
                <Footer variant={FooterVariantEnum.COMPACT} />
              </RenderIf>
            </PageFrame>
          </FadeInWrapper>
        )}
      </AnimatePresence>
    </>
  )
}
