import { createContext, useContext } from 'react'
import { AppProviderInterface } from '@shared/interfaces/app-provider.interface'

export const AppContext = createContext<AppProviderInterface>({
  searchQuery: '',
  category: '',
  referer: '',
  handleOnSearch: () => '',
  handleCategory: () => '',
  handleReferer: () => '',
  currentPath: '',
  handleCurrentPath: () => '',
  isLoading: false,
  toggleIsLoading: () => false,
})

// Allow to components access to the context provider
export const useAppProvider = () => useContext(AppContext)
