import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { useEffect, useState } from 'react'
import { EXPLORE_META_URL } from '@shared/constants/root.constants'
import { WHITE_LABEL_CHANNELS } from '@shared/constants/whitelabels.contants'
import { useDispatch, useSelector } from 'react-redux'
import { getChannelThunk } from '@store/channel-slice'
import { selectChannelData, selectStatus } from '@store/channel-slice/_selectors'

export default function use404Theme(channel: ChannelInterface | undefined) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const [channelData, setChannelData] = useState<ChannelInterface>()

  const [channelPath, setChannelPath] = useState<string>()
  const stateChanelData = useSelector(selectChannelData(channelPath))
  const fetchStatus = useSelector(selectStatus)

  useEffect(() => {
    if (!channel) {
      const currentPath = window.location.host
      // If the origin page is not Vidzing, fetch channel fallback data
      if (!EXPLORE_META_URL.includes(currentPath)) {
        const findFallback = WHITE_LABEL_CHANNELS.find((channel) =>
          channel.host.includes(currentPath),
        )

        // Do we have whitelabel fallback data?
        if (findFallback) {
          setChannelPath(findFallback.channelPath)
          dispatch(getChannelThunk(findFallback.channelPath))
        } else {
          setLoading(false)
        }
      } else {
        // If the origin path is vidzing, set loading as false
        setLoading(false)
      }
    } else {
      setChannelData(channel)
      setLoading(false)
    }
  }, [channel, dispatch])

  useEffect(() => {
    if (stateChanelData) {
      setChannelData(stateChanelData)
    }
  }, [stateChanelData])

  useEffect(() => {
    const validStates = ['succeeded', 'failed']
    if (validStates.includes(fetchStatus)) {
      setLoading(false)
    }
  }, [fetchStatus])

  return { loading, channelData }
}
